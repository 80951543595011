:root {
  --dark-bg: #1d1e24;
  --dark-second: #2d3748;
  --dark-txt: #dfe5ef;
  --dark-green: #81e6d9;
  --dark-green-3: #319795;
  --felicis-green: #00ffbc;
  --font-gray: #98a2b3;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "Cera Pro";
    src: url("/fonts/Cera_Pro.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Cera Pro";
    src: url("/fonts/Cera_Pro_Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    src: url("/fonts/inter.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
}
body {
  background: var(--dark-bg);
}
a,
button,
p,
h2 {
  font-family: "Cera Pro", "Trebuchet MS", sans-serif;
}
#site-background h1 {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
a.block:focus-visible {
  outline: none;
}
#logodiv {
  height: inherit;
  width: 125px;
}
.felicis {
  font-family: "Cera Pro", "Trebuchet MS", sans-serif;
  font-size: 44px;
  height: 80px;
  line-height: 80px;
  margin-left: -30px;
}
.hero-text {
  line-height: 1.125;
}

#site-background {
  padding: 30px 0;
  background: var(--dark-bg);
  background: radial-gradient(
    ellipse at bottom,
    var(--dark-bg) 0%,
    #25262e 95%
  );
}
#site-background::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url(/hero-bg.svg) center center repeat;
  background-size: 1800px;
  mix-blend-mode: color-dodge;
  opacity: 0.04;
}

#ContactBtn {
  appearance: none;
  border: 4px solid var(--dark-green);
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.2;
  min-width: 200px;
  outline: none;
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  user-select: none;
  white-space: nowrap;
  will-change: transform, opacity;
}
#site-services,
#site-skills,
#site-who,
#contact {
  padding: 60px 120px;
}
#site-services h2,
#site-skills h2,
#site-who h2,
#contact h2 {
  font-size: 44px;
  line-height: 48px;
}
#services-grid > div {
  padding: 40px;
}

#services-grid h1 {
  font-size: 20px;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.ccr-line {
  height: 3px;
  margin: 0 auto;
  margin-bottom: 24px;
  margin-top: 20px;
  width: 87px;
}
/* Logos */
.text-split-with-logo-grid_gridItem__rXgXT {
  padding: 4px;
}

@media (min-width: 400px) {
  .text-split-with-logo-grid_gridItem__rXgXT {
    padding: 8px;
  }
}

.text-split-with-logo-grid_itemAspect__1bp3W {
  padding-bottom: 66.66%;
}

@media (min-width: 900px) {
  .text-split-with-logo-grid_itemAspect__1bp3W {
    padding-bottom: 75%;
  }
}

@media (min-width: 1120px) {
  .text-split-with-logo-grid_itemAspect__1bp3W {
    padding-bottom: 66.66%;
  }
}

.text-split-with-logo-grid_itemAspectInner__3qbGv {
  background: #fff;
  transition: box-shadow 0.25s;
}

.text-split-with-logo-grid_textSplitWithLogoGrid__1_3fp svg {
  max-width: 50%;
}

.text-split-with-logo-grid_textSplitWithLogoGrid__1_3fp {
  width: 75%;
  margin: 0 auto;
}

.text-split-with-logo-grid_gridItem__rXgXT {
  flex: 1 0 21%;
}
@media screen and (min-width: 1024px) {
  .hero-text {
    font-size: 5.5rem !important;
  }
}
@media screen and (max-width: 1024px) {
  #services-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  #services-grid > div {
    width: 45%;
  }
}
@media screen and (max-width: 1023px) {
  h1 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 800px) {
  #services-grid > div {
    width: 100%;
  }
  .text-split-with-logo-grid_gridItem__rXgXT {
    flex: 50%;
  }
}

.contactForm {
  margin: 0 auto;
}
.contactForm label {
  margin-bottom: 25px;
}
.contactForm input,
.contactForm textarea {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  font-size: 1.25rem;
  margin: 0;
  min-width: 100%;
  outline: none;
  padding: 12px 0;
  text-indent: 10px;
  transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
    background-size 150ms ease-in, background-color 150ms ease-in;
}

.contactForm input:focus,
.contactForm textarea:focus {
  background-color: #111216;
  background-image: linear-gradient(
    to top,
    var(--dark-green),
    var(--dark-green) 2px,
    transparent 2px,
    transparent 100%
  );
  background-size: 100% 100%;
  box-shadow: 0 1px 1px -1px rgb(0 0 0 / 20%), 0 4px 4px -2px rgb(0 0 0 / 20%),
    inset 0 0 0 1px rgb(255 255 255 / 10%);
}
.contactForm button {
  appearance: none;
  border: 4px solid var(--dark-green);
  border-radius: 5px;
  font-size: 1.2rem;
  line-height: 1.2;
  min-width: 200px;
  outline: none;
  padding: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 600px) {
  #logodiv {
    max-width: 110px;
    margin-left: -2rem;
  }
  h1 {
    font-size: 3rem;
  }
  .hero-text br {
    display: none !important;
  }
  #site-services,
  #site-skills,
  #site-who,
  #contact {
    padding: 40px;
  }
  .text-split-with-logo-grid_textSplitWithLogoGrid__1_3fp svg {
    max-height: 100px;
  }
  .text-split-with-logo-grid_textSplitWithLogoGrid__1_3fp,
  .contactForm {
    width: 100%;
  }
}

#ContactBtn:hover,
#ContactBtn:focus,
#ContactBtn:active,
.contactForm button:hover,
.contactForm button:focus,
.contactForm button:active {
  border: 0px;
  background-color: var(--felicis-green);
  color: var(--dark-bg);
}
.skills-svg {
  margin: 0 auto;
  max-width: 125px;
}
